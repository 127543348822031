<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_iabm.booking')}}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col sm="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="8">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_iabm.booking')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(total_rent)="data">
                                          {{ $n(data.item.total_rent) }}
                                        </template>
                                        <template v-slot:cell(floor_no)="data">
                                          {{ $n(data.item.floor_no) }}
                                        </template>
                                        <template v-slot:cell(room_no)="data">
                                          {{ $n(data.item.room_no) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                          {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(start_date)="data">
                                          <span class="capitalize">{{ data.item.start_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(end_date)="data">
                                          <span class="capitalize">{{ data.item.end_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button v-b-modal.modal-4 variant="btn btn-success mr-1 mb-1 btn-sm" size="sm" @click="edit(data.item)">
                                              <i class="fas fa-eye"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
            <!-- <pre>{{listData}}</pre>
            <pre>{{$store.state.ExternalUserService.trainingPanel.commonObj.roomTypeList}}</pre> -->
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { bookingApplicationList, circularPublicationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import ExportPdf from './export_pdf_details'
import { mapGetters } from 'vuex'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Details
    },
    data () {
        return {
            search: {
                org_id: 0,
                fiscal_year_id: 0
            }
        }
    },
    computed: {
      ...mapGetters({
        authUser: 'Auth/authUser'
      }),
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return this.$t('elearning_iabm.booking') + ' ' + this.$t('globalTrans.view')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('globalTrans.fiscal_year'), class: 'text-left' },
              { label: this.$t('elearning_config.organization'), class: 'text-left' },
              { label: this.$t('globalTrans.name'), class: 'text-left' },
              { label: this.$t('globalTrans.mobile'), class: 'text-left' },
              { label: this.$t('elearning_config.room_type'), class: 'text-left' },
              { label: this.$t('elearning_config.floor_no'), class: 'text-left' },
              { label: this.$t('elearning_config.room_no'), class: 'text-left' },
              { label: this.$t('globalTrans.start_date'), class: 'text-left' },
              { label: this.$t('globalTrans.end_date'), class: 'text-left' },
              { label: this.$t('elearning_config.room_rent'), class: 'text-left' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year_bn' },
              { key: 'org_bn' },
              { key: 'name_bn' },
              { key: 'mobile' },
              { key: 'room_type_bn' },
              { key: 'floor_no' },
              { key: 'room_no' },
              { key: 'start_date' },
              { key: 'end_date' },
              { key: 'total_rent' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year' },
              { key: 'org' },
              { key: 'name' },
              { key: 'mobile' },
              { key: 'room_type' },
              { key: 'floor_no' },
              { key: 'room_no' },
              { key: 'start_date' },
              { key: 'end_date' },
              { key: 'total_rent' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.loadData()
    },
    mounted () {
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
          this.changeStatus(trainingElearningServiceBaseUrl, circularPublicationToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
        },
        loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, bookingApplicationList, params).then(response => {
            if (response.success) {
              this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
              this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
        },
        getCustomDataList (data) {
          const listData = data.map(item => {
            const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              orgData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }

            const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalYearData = {}
            if (typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }
            const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office = officeObj.text_en
              officeData.office_bn = officeObj.text_bn
            } else {
              officeData.office = ''
              officeData.office_bn = ''
            }
            const officeTypeObj = this.$store.state.CommonService.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.office_type_id))
            const officeTypeData = {}
            if (typeof officeTypeObj !== 'undefined') {
              officeTypeData.office_type = officeTypeObj.text_en
              officeTypeData.office_type_bn = officeTypeObj.text_bn
            } else {
              officeTypeData.office_type = ''
              officeTypeData.office_type_bn = ''
            }
            const roomTypeObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.roomTypeList.find(roomType => roomType.value === parseInt(item.room_type_id))
            const roomTypeData = {}
            if (typeof roomTypeObj !== 'undefined') {
              roomTypeData.room_type = roomTypeObj.text_en
              roomTypeData.room_type_bn = roomTypeObj.text_bn
            } else {
              roomTypeData.room_type = ''
              roomTypeData.room_type_bn = ''
            }
            const roomObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.roomList.find(roomList => parseInt(roomList.value) === parseInt(item.room_id))
            const roomData = {}
            if (typeof roomObj !== 'undefined') {
              roomData.room_no = roomObj.text_en
            } else {
              roomData.room_no = ''
            }
            return Object.assign({}, item, orgData, fiscalYearData, roomData, officeTypeData, officeData, roomTypeData)
          })
          return listData
        },
        pdfExport () {
          const reportTitle = this.$i18n.locale === 'en' ? 'Circular Publication' : 'বিজ্ঞপ্তি প্রকাশ'
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this)
        }
    }
}
</script>
